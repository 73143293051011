<template>
  <div>
    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: cornflowerblue">Tambah Jadwal Tryout</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/admin/plan">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-form
        method="post"
        enctype="multipart/form-data"
        @submit.prevent="addData()"
      >
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Nama Jadwal</b></label>
            <b-form-input id="nama" v-model="nama" placeholder="Nama Jadwal" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Deskripsi</b></label>
            <b-form-input
              id="deskripsi"
              v-model="deskripsi"
              placeholder="Deskripsi"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Nama Event/Acara</b></label>
            <v-select
              name="register-kampus"
              placeholder="Cari Nama Acara"
              v-model="id_event"
              :value="id"
              :reduce="(nama) => nama.id"
              :options="listEvent"
              label="nama"
            >
            </v-select>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Tanggal Mulai</b></label>
            <flat-pickr v-model="tanggal" class="form-control" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Jam Mulai</b></label>
            <flat-pickr
              v-model="waktu"
              class="form-control"
              :config="{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true
              }"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"
              ><b>Jumlah Soal (dalam angka)</b></label
            >
            <b-form-input
              id="jumlah_soal"
              v-model="jumlah_soal"
              placeholder="Jumlah Soal"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"
              ><b>Durasi Ujian (dalam menit)</b></label
            >
            <b-form-input
              id="durasi"
              v-model="durasi"
              placeholder="Durasi Ujian"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Status</b></label>
            <b-form-select
              id="status"
              v-model="status"
              :options="option_status"
              placeholder="Status Jadwal"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Catatan</b></label>
            <b-form-input
              id="catatan"
              v-model="catatan"
              placeholder="Catatan"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="success" style="width: 100%" type="submit">
              <feather-icon icon="SaveIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Simpan</b></span
              >
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="danger"
              style="width: 100%"
              type="button"
              @click="cancelButton('info')"
            >
              <feather-icon icon="XCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Batal</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BForm,
    BTh,
    BToast,
    BTd,
    flatPickr,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      plan: [],
      id: this.$route.params.id,
      nama: "",
      deskripsi: "",
      catatan: "",
      listEvent: [],
      jumlah_soal: "",
      durasi: "",
      tanggal: "",
      waktu: "",
      status: "",
      option_status: [
        { text: "Aktif", value: "Aktif" },
        { text: "NonAktif", value: "NonAktif" },
      ],
    };
  },
  mounted() {
    this.getEvent();
  },
  methods: {
    async addData() {
      //   e.prplanDefault();
      const formData = new FormData();
      formData.append("id_user", this.id_user);
      formData.append("id_event", this.id_event);
      formData.append("nama", this.nama);
      formData.append("deskripsi", this.deskripsi);
      formData.append("durasi", this.durasi);
      formData.append("catatan", this.catatan);
      formData.append("jumlah_soal", this.jumlah_soal);
      formData.append("tanggal", this.tanggal);
      formData.append("waktu", this.waktu);
      formData.append("status", this.status);
      await this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "plans/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Berhasil Ubah Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          this.$router.push({ name: "list_plan" });
          this.nama = "";
          this.deskripsi = "";
          this.catatan = "";
          this.id_user = "";
          this.id_event = "";
          this.durasi = "";
          this.jumlah_soal = "";
          this.status = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton(variant = null) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "XCircleIcon",
            text: "Cancel Perubahan Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "list_plan" });
    },

    getEvent() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "events/view")
        .then((res) => {
          this.listEvent = res.data.data;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>